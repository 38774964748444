import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import { routes } from "~/routes";

function Clock() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000); // Update the time every second

        return () => clearInterval(interval); // Clean up on unmount
    }, []);

    const timeOptions = {
        timeZone: "Asia/Ho_Chi_Minh",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    };

    const dateOptions = {
        timeZone: "Asia/Ho_Chi_Minh",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    return (
        <div>
            <p>
                {new Intl.DateTimeFormat("en-GB", timeOptions).format(time)} -
                {new Intl.DateTimeFormat("en-GB", dateOptions).format(time)}
            </p>
        </div>
    );
}

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {routes.map((route, _) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                </Routes>
            </BrowserRouter>
            <footer style={footerStyle}>
                <p>
                    &copy; Nguyễn Liên Mạnh và Phan Văn Tài <Clock />{" "}
                </p>
            </footer>
            <ToastContainer />
        </>
    );
}

const footerStyle = {
    backgroundColor: "#fff",
    color: "#333",
    textAlign: "center",
    position: "fixed",
    bottom: 0,
    width: "100%",
    fontSize: "14px",
};

export default App;
