import React from "react";
import TippyHeadless from "@tippyjs/react/headless";
import styles from "./Help.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Help = () => (
    <div>
        <TippyHeadless
            render={(attrs) => (
                <div className={cx("wrapper")} tabIndex="-1" {...attrs}>
                    <p className={cx("content")}>
                        Hiện tại, trang web của tôi phục vụ hình ảnh ít hơn{" "}
                        <b> 1MB </b> và video ít hơn <b>10 MB</b> và không vượt
                        quá {""}
                        <b>
                            1&nbsp; phút.
                            <br />
                        </b>{" "}
                        Hiển thị bạn có thể truy cập{" "}
                        <a
                            className={cx("link")}
                            href="https://tinypng.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            trang này
                        </a>
                        <span> để nén các tập tin PNG và JPEG.</span>
                        <br />
                        <span>
                            Hoặc sử dụng{" "}
                            <a
                                className={cx("link")}
                                href="https://www.online-convert.com/video-compressor/compress-mp4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                trang này
                            </a>{" "}
                            để nén cả hình ảnh và video.
                        </span>
                        <span>
                            Đây là video hưỡng dẫn{" "}
                            <a
                                className={cx("link")}
                                href="https://www.youtube.com/shorts/3ELfVoVaSqs"
                                target="_blank"
                                rel="noreferrer"
                            >
                                chọn vào đây
                            </a>{" "}
                            để xem chi tiết
                        </span>
                    </p>
                </div>
            )}
            interactive
            delay={[100, 500]}
            // offset={[25, 10]}
            placement="top-start"
            hideOnClick={false}
        >
            <div className={cx("help-me")}>Hướng dẫn</div>
        </TippyHeadless>
    </div>
);

export default Help;
